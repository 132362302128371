<template>
	<div class="systemNotice content">
		<div class="title">
			<img src="../../assets/megaphone.png" style="width: 20px;" /> <span style="margin-left: 6px;">系统公告</span>
		</div>
		<div :style="{ 'min-height': `${tableHeight}px` }">
			<a-empty v-if="noticeData.length === 0" style="color: rgba(0,0,0,0.45); margin-top: 150px;"></a-empty>
			<div @click="goToDetail(item)" class="title_msg" v-for="(item, index) in noticeData">
				<div v-if="item.isRead && item.isRead === 2"
					style="width: 8px;height: 8px;border-radius: 8px;background: #f5222d;margin-right: 8px;"></div>
				<div v-else style="width: 8px;height: 8px;border-radius: 8px;background: #D8D8D8;margin-right: 8px;"></div>
				{{ item.titleName }}
				<div style="flex: 1;"></div>
				<div style="font-size: 14px;color: rgba(0, 0, 0, 0.45);">{{ item.publicTime }}</div>
			</div>
		</div>
		<div class="default-pagination">
			<a-pagination v-model="current" :page-size-options="pageSizeOptions" :total="total" show-size-changer
				:page-size="pageSize" @change="change" @showSizeChange="onShowSizeChange" hideOnSinglePage>
				<template slot="buildOptionText" slot-scope="props">
					<span v-if="props.value !== '50'">{{ props.value }}条/页</span>
					<span v-if="props.value === '50'">全部</span>
				</template>
			</a-pagination>
		</div>
		
	</div>
</template>

<script>
const tableHeight = document.documentElement.clientHeight - 300;
import { querynoticepagelist } from '../../api/anotherIndex'
export default {
	data() {
		return {
			pageSizeOptions: ['10', '20', '30', '40', '50'],
			current: 1,
			pageSize: 10,
			total: 10,
			noticeData: [],
			tableHeight
		}
	},
	methods: {
		onShowSizeChange(current, pageSize) {
			this.pageSize = pageSize;
			this.current = 1;
			// let data = "?PageNum="+this.current+"&PageSize="+this.pageSize
			this.querynoticepagelist()
		},
		change(page, pageSize) {
			this.current = page;
			//let data = "?PageNum="+this.current+"&PageSize="+this.pageSize
			this.querynoticepagelist()
		},
		async querynoticepagelist() {
			const res = await querynoticepagelist({
				PageNum: this.current,
				PageSize: this.pageSize
			})
			if (res.success) {
				//console.log(res)
				// this.total = 
				this.noticeData = res.data.data;
				this.total = res.data.count;
				//console.log(res.data.count)
			}
		},
		goToDetail(data) {
			this.$router.push({ path: 'systemNoticeDetail', query: { id: data.noticeId } })
		}
	},
	created() {
		this.querynoticepagelist()
	}
}
</script>

<style scoped>
.content {
	width: 98%;
	border-radius: 2px;
	min-height: 808px;
	background: #FFFFFF;
	margin: 0 auto;
	margin-bottom: 16px;
	margin-top: 16px;
}

.title {
	font-size: 18px;
	color: rgba(0, 0, 0, 0.85);
	text-align: left;
	border-bottom: 1px solid #EBEBEB;
	margin: 0 16px;
	padding: 16px 0;
	font-weight: 550;
	display: flex;
	align-items: center;
}

.title_msg {
	font-size: 14px;
	color: rgba(0, 0, 0, 0.85);
	text-align: left;
	border-bottom: 1px solid #EBEBEB;
	margin: 0 16px;
	padding: 16px 16px 16px 8px;
	font-weight: 550;
	display: flex;
	align-items: center;
	cursor: pointer;
	transition: all .3s;
	border-radius: 2px;
}

.title_msg:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
</style>
<style scoped>
.systemNotice .ant-pagination {
	float: none !important;
	margin-top: 32px;
}

.systemNotice .ant-pagination-item-link {
	border-color: transparent !important;
	color: rgba(0, 0, 0, 0.45) !important;
}



.systemNotice .ant-pagination-item-active a {
	color: white !important;
}
</style>

<style lang="less" scoped>
.content {
	box-shadow: @srims-primary-box-shadow-light;
	border-radius: 4px;
}
</style>